//@flow
import { defaultService } from "./defaultService";
import { urlsConstants } from "../_constants/urls.constant";
import _ from 'lodash';
var dados;

export const convenioService = {
    findAll,
    atendePorEstabelecimentoOuEstabelecimentoMedico,
    findPlanoConvenioByConvenio,
    findByEstabelecimentoOrEstabelecimentoMedico,
    findAllPlansByConvenios,
    doSave,
    doGet,
    findByFiltro,
    findByEstabelecimentoPublic
};

function findAll(parent) {
    if (parent) {
        if (parent.estabelecimento && parent.medico) {
            return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimentoAndMedico/${parent.estabelecimento}/${parent.medico}`);
        }
        if (parent.estabelecimento) {
            return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimento/${parent.estabelecimento}`);
        }
        return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimento/${parent}`);
    }

    return defaultService.doGet(`${urlsConstants.CONVENIO}findAll`);
}

function findByEstabelecimentoPublic(idEstabelecimento,values) {
    let body;
    if(values){
        body = _.cloneDeep(values);
    }else{
        body = {};
    }
    return defaultService.doPost(`${urlsConstants.PUBLIC_CONVENIO}findByEstabelecimento/${idEstabelecimento}`,body);
}



/**
 * Find all plans
 *
 * @return {Promise<AxiosResponse<any>>}
 */
function findAllPlansByConvenios(idsConvenio: number[]) {
    if (idsConvenio) {
        return defaultService.doPost(`${urlsConstants.CONVENIO}findAllPlansByConvenios`, idsConvenio);
    } else {
        return null;
    }
}

function findPlanoConvenioByConvenio(idConvenio) {
    return defaultService.doGet(`${urlsConstants.CONVENIO}findPlanoConvenioByConvenio/${idConvenio}`);
}

function findByEstabelecimentoOrEstabelecimentoMedico(filtro) {
    let idEstabelecimento = filtro.estabelecimento
    let idEstabelecimentoMedico = filtro.estabelecimentoMedico
    if (idEstabelecimento == null && idEstabelecimentoMedico === null) {
        return defaultService.doGet(`${urlsConstants.CONVENIO}findAll`);
    }
    return defaultService.doGet(`${urlsConstants.CONVENIO}findByEstabelecimentoOrEstabelecimentoMedico/${idEstabelecimento}/${idEstabelecimentoMedico}`);
}


function atendePorEstabelecimentoOuEstabelecimentoMedico(
    idEstabelecimentoMedico,
    idConvenio,
    dataConsulta,
    idEstabelecimento,
    tipoAgenda
) {
    return defaultService.doPost(
        `${urlsConstants.CONVENIO}atendePorEstabelecimentoOuEstabelecimentoMedico/${idEstabelecimentoMedico}/${idConvenio}/${idEstabelecimento}/${tipoAgenda}`,
        dataConsulta
    );
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
    return defaultService.findByFiltro(
        `${urlsConstants.CONVENIO}list`,
        options,
        filter,
    )
}

function doSave(entity: any) {
    return defaultService.doSave(urlsConstants.CONVENIO, entity)
}

function doGet(id) {
    return defaultService.doGet(`${urlsConstants.CONVENIO}${id}`);
}