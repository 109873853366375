import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import React from 'react';
import { Form, FormGroup } from 'reactstrap';
import { useValidationsBR } from 'validations-br';
import * as Yup from 'yup';
import CpfInput from '../../../components/inputs/CpfInput';
import CreditCardInput from '../../../components/inputs/CreditCardInput';
import FormInput from '../../../components/inputs/FormInput';
import InputViewEdit from '../../../components/inputs/InputViewEdit';
import SelectInput from '../../../components/inputs/SelectInput';
import Cards from '../../../images/cards.png';
import { objectsConstants } from '../../../_constants/objects.constants';
import { cardHelper } from '../../../_helpers/card.helper';
import { dateHelper } from '../../../_helpers/date.helper';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { translate } from '../../../_helpers/messages.helper';
import { pagamentoService } from '../../../_services/pagamento.service';

const REQUIRED_STRING_VALIDATE = Yup.string()
  .nullable()
  .required('Campo obrigatório');

const Step5ValidateModel = Yup.object().shape({
  tipoPagamento: REQUIRED_STRING_VALIDATE,
  formaPagamento: Yup.object()
    .nullable()
    .when('tipoPagamento', {
      is: (tipoPagamento) => tipoPagamento === objectsConstants.CARTAO_CREDITO,
      then: Yup.object().shape({
        numeroCartao: Yup.object()
          .nullable()
          .when('cardInvalid', (cardInvalid, schema) => {
            if (cardInvalid) {
              return Yup.string().required('Número do cartão Inválido');
            } else {
              return REQUIRED_STRING_VALIDATE;
            }
          }),
        nomeTitular: REQUIRED_STRING_VALIDATE,
        validadeMes: REQUIRED_STRING_VALIDATE,
        validadeAno: REQUIRED_STRING_VALIDATE,
        cvv: REQUIRED_STRING_VALIDATE,
        cpf: Yup.string()
          .required('Campo obrigatório')
          .test('is-cpf', 'CPF não é válido', function (value) {
            const { path, createError } = this;
            if (!useValidationsBR('cpf', value)) {
              return createError({ path, message: 'CPF não é válido' });
            }
            return true;
          }),
      }),
    }),
});

class Step5 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formaPagamento: '',
      cardNumber: '',
    };
  }

  changePayment(value) {
    this.setState({
      formaPagamento: value,
    });
  }

  saveForm = () => {
    const { callbackOnSave } = this.props;
    let _this = this;
    let values = _this.form.state.values;
    _.set(values, 'formaPagamento.type', _.get(values, 'tipoPagamento'));
    if (_.get(values, 'tipoPagamento') == objectsConstants.CARTAO_CREDITO) {
      _.set(
        values,
        'formaPagamento.brand',
        cardHelper.getBandeiraCartao(
          _.get(values, 'formaPagamento.numeroCartao')
        )
      );
    }

    let promise = new Promise(function (resolve) {
      _this.form.validateForm(values).then((error) => {
        _this.props.loading(true);
        console.log(error);
        if (_.isEmpty(error)) {
          pagamentoService.doUpdate(values).then(
            (response) => {
              _this.props.success({
                message: `Dados de pagamento, atualizados com sucesso!`,
              });
              _this.props.pagamentoAutorizado();
              _this.setState({ cardNumber: response.data.cardNumber }, () => {
                _this.props.loading(false);
                resolve(true);
              });
            },
            (erros) => {
              console.log(erros);
              let response = erros.response.data;
              if (response && response.messages) {
                for (var i = 0; i < response.messages.length; i++) {
                  let erroItem = response.messages[i];
                  this.props.error({
                    message: translate(erroItem.message.code),
                  });
                }
              }

              _this.props.loading(false);
              resolve(false);
            }
          );
        } else {
          _this.props.loading(false);
          resolve(false);
        }
      });
    });
    return promise;
  };
  render() {
    return (
      <Formik
        validationSchema={Step5ValidateModel}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={this.props.entity}
        ref={(form) => {
          this.form = form;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
          validationSchema,
          validateForm,
          setValues,
        }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <div className="row form-inside">
              <div className="col-12 section-form">
                {!this.props.viewMode && (
                  <React.Fragment>
                    <p>Passo 5 de 6 - Dados de pagamento</p>

                    <div className="row">
                      <FormGroup className="col-4">
                        <InputViewEdit
                          component={SelectInput}
                          options={objectsConstants.FORMA_PAGAMENTO}
                          name={'tipoPagamento'}
                          label=""
                          placeholder="Selecione a forma de pagamento"
                          onChange={(name, value) => {
                            this.props.handleChange(
                              'formaPagamento.type',
                              value
                            );
                            this.props.handleChange(name, value);
                          }}
                          valueKey={'name'}
                          labelKey={'description'}
                          required={true}
                          returnFullObject={false}
                          viewMode={this.props.viewMode}
                          value={_.get(values, 'tipoPagamento')}
                          id="tipoPagamento"
                          erroMensagem={_.get(errors, 'tipoPagamento')}
                          defaultValue={formatterHelper.getLabelEnum(
                            values.tipoPagamento,
                            objectsConstants.FORMA_PAGAMENTO,
                            'name',
                            'description'
                          )}
                        />
                      </FormGroup>
                    </div>
                  </React.Fragment>
                )}
                {/* FORMA DE PAGAMENTO COM CARTÃO DE CRÉDITO */}
                {(_.get(values, 'tipoPagamento') ===
                  objectsConstants.CARTAO_CREDITO ||
                  _.get(this.props.entity, 'metodo') ===
                    objectsConstants.CARTAO_CREDITO) && (
                  <div className="row">
                    {this.props.viewMode &&
                      (_.get(this.props.entity, 'cartaoNumeroFinal') ||
                        _.get(this.state, 'cardNumber')) && (
                        <div className="">
                          <p>
                            Cartão de crédito numero:{' '}
                            <b>
                              {_.get(this.props.entity, 'cartaoNumeroFinal')
                                ? _.get(this.props.entity, 'cartaoNumeroFinal')
                                : _.get(this.state, 'cardNumber')}
                            </b>
                          </p>
                        </div>
                      )}
                    {!this.props.viewMode && (
                      <React.Fragment>
                        <FormGroup className="col-4">
                          <InputViewEdit
                            component={CreditCardInput}
                            label="Número do cartão"
                            placeholder={'000.000.000.000'}
                            id={'numeroCartao'}
                            name="formaPagamento.numeroCartao"
                            type={'number'}
                            value={_.get(values, 'formaPagamento.numeroCartao')}
                            required={true}
                            onChange={(name, value) => {
                              // this.validaCataoCredito(value, setFieldValue);
                              this.props.handleChange(name, value);
                            }}
                            viewMode={this.props.viewMode}
                            erroMensagem={_.get(
                              errors,
                              'formaPagamento.numeroCartao'
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="col-4">
                          <InputViewEdit
                            component={FormInput}
                            label="Nome do titular"
                            type="text"
                            name="formaPagamento.nomeTitular"
                            viewMode={this.props.viewMode}
                            id="nomeTitular"
                            placeholder="Nome do titular idêntico ao impresso no cartão"
                            required={true}
                            value={_.get(values, 'formaPagamento.nomeTitular')}
                            onChange={this.props.handleChange}
                            erroMensagem={_.get(
                              errors,
                              'formaPagamento.nomeTitular'
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="col-2">
                          <InputViewEdit
                            component={SelectInput}
                            options={objectsConstants.MESES}
                            label="Mês de validade"
                            id="validadeMes"
                            name="formaPagamento.validadeMes"
                            viewMode={this.props.viewMode}
                            valueKey={'name'}
                            labelKey={'description'}
                            required={true}
                            returnFullObject={false}
                            value={_.get(values, 'formaPagamento.validadeMes')}
                            placeholder="MM"
                            onChange={this.props.handleChange}
                            erroMensagem={_.get(
                              errors,
                              'formaPagamento.validadeMes'
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="col-2">
                          <InputViewEdit
                            component={SelectInput}
                            options={dateHelper.getFutureYears()}
                            label="Ano de validade"
                            id="validadeAno"
                            name="formaPagamento.validadeAno"
                            value={_.get(values, 'formaPagamento.validadeAno')}
                            valueKey={'name'}
                            labelKey={'description'}
                            required={true}
                            returnFullObject={false}
                            viewMode={this.props.viewMode}
                            placeholder="AAAA"
                            onChange={this.props.handleChange}
                            erroMensagem={_.get(
                              errors,
                              'formaPagamento.validadeAno'
                            )}
                          />
                        </FormGroup>
                        <FormGroup className="col-4">
                          <InputViewEdit
                            component={FormInput}
                            label="Código de segurança"
                            type="number"
                            name="formaPagamento.cvv"
                            viewMode={this.props.viewMode}
                            id="cvv"
                            placeholder="CVV"
                            required={true}
                            value={_.get(values, 'formaPagamento.cvv')}
                            onChange={this.props.handleChange}
                            erroMensagem={_.get(errors, 'formaPagamento.cvv')}
                          />
                        </FormGroup>
                        <FormGroup className="col-4">
                          <InputViewEdit
                            component={CpfInput}
                            label="CPF do titular"
                            placeholder={'CPF'}
                            id={'cpf'}
                            name="formaPagamento.cpf"
                            viewMode={this.props.viewMode}
                            type={'number'}
                            value={_.get(values, 'formaPagamento.cpf')}
                            required={true}
                            onChange={this.props.handleChange}
                            erroMensagem={_.get(errors, 'formaPagamento.cpf')}
                          />
                        </FormGroup>
                        <div className="col-4 d-flex justify-content-center align-items-end">
                          <img
                            src={Cards}
                            alt="Bandeiras cartões"
                            className="img-fluid"
                          ></img>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )}
                {/* FORMA DE PAGAMENTO EM BOLETO BANCARIO */}
                {(_.get(values, 'tipoPagamento') === objectsConstants.BOLETO ||
                  _.get(this.props.entity, 'metodo') ===
                    objectsConstants.BOLETO) && (
                  <div className="row">
                    Um boleto será gerado e enviado par o e-mail de cadastro!
                  </div>
                )}
                {/* FORMA DE PAGAMENTO EM BOLETO BANCARIO */}
                {(_.get(values, 'tipoPagamento') ===
                  objectsConstants.TRIAL_VERSION ||
                  _.get(this.props.entity, 'metodo') ===
                    objectsConstants.TRIAL_VERSION) && (
                  <div className="row">
                    Você terá 30 dias para testar o sistema gratuitamente e
                    aproveitar todos os recursos sem compromisso!
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default Step5;
