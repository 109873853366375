//@flow
import { isEmptyObject } from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../_constants/objects.constants';
import { dateHelper } from '../../_helpers/date.helper';
import { agendaService } from '../../_services/agenda.service';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import Avatar from '../../components/Avatar/Avatar';
import { ModalCrud } from '../../components/Modal/ModalCrud';
import { ModalLocal } from '../../components/Modal/ModalLocal';
import { ModalLocalRecepcao } from '../../components/Modal/ModalLocalRecepcao';
import TopoTitleComponente from '../../components/PageTitle/TopoTitleComponente';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import IconAgendaOff from '../../images/icon-agendaMedica-off.svg';
// import nextId from 'react-id-generator';
import { CacheAgenda } from '../../_components/CacheAgenda';
import { legendHelper } from '../../_helpers/legend.helper';
import { marcacaoService } from '../../_services/marcacao.service';
import { medicoService } from '../../_services/medico.service';
import { userService } from '../../_services/user.service';
import type { medico } from '../../_types/medico.type';
import MarcacaoForm from '../marcacao/MarcacaoForm';
import TriagemPage from '../triagem/TriagemPage';
import { agendaAction } from './../../_actions/agenda.action';
import { headerAction } from './../../_actions/header.action';
import { getAgendasDay } from './../../_helpers/agenda.helper';
import HorarioRow from './../../components/horarios/HorarioRow';
import FilaAtendimentoButton from './filaAtendimento/FilaAtendimentoButton';

import { Can } from '@casl/react';
import FilaMarcacaoButton from './filaMarcacao/FilaMarcacaoButton';
const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

type Props = {
  date: any,
  marcacoes?: any,
  medico: medico,
  onStatusChanged: any,
  dispatch: any,
  onGoBack: any,
  onMarcacaoCreated: any,
  maxMarcacaoDia: number,
  value?: any,
};

type State = {
  showAnamnese: boolean,
  idPaciente: number,
  showNewPaciente: boolean,
  showPacienteConsultaAvulsa: boolean,
  nameNewPaciente: string,
  horaNewPaciente: string,
  refRow: string,
  abreInputEncaixe: boolean,
  showConsultaAvulsa: boolean,
  showPacientesAtender: boolean,
  showFilaAtendimento: Boolean,
  isEditOrConfirmMode: any,
};
const defaultPaciente = {
  nome: '',
  email: '',
  // cpfCnpj: '',
  marcacaoRecorrente: false,
  telefones: [{}],
  tipoAtendimento: objectsConstants.TIPO_ATENDIMENTO_PARTICULAR,
  pacienteConvenios: [
    {
      convenio: {},
      plano: '',
      carteirinha: '',
      validadeCarteirinha: '',
    },
  ],
};
class AgendaHorariosPage extends React.Component<Props, State> {
  static defaultProps = {
    backUrl: '/agenda',
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return true;
  }

  constructor(props: Props) {
    super(props);
    let listPerfil = _.get(props.user, 'role.role.listaPerfil', []);
    let isSecretaria = userService.isSecretaria();
    let listLocaoAtendimento = _.get(
      props.user,
      'listLocalAtendimentoRecepcao',
      []
    );
    let permissaoSecretaria = _.filter(listPerfil, (p) => {
      if (isSecretaria && p.etapaAtendimento.local) {
        return (
          _.filter(listLocaoAtendimento, (local) => {
            return local.local.indexOf(p.etapaAtendimento.local) >= 0;
          }).length > 0
        );
      }
      return true;
    });
    let filaAtendimento = _.maxBy(
      _.filter(objectsConstants.ROLE_STATUS_AGENDA, (r) => {
        if (r.permission) {
          if (!isSecretaria) {
            return (
              _.filter(_.get(props.user, 'role.role.listRolePermission', []), {
                casoDeUso: { nome: r.permission },
              }).length > 0
            );
          } else {
            return (
              _.filter(permissaoSecretaria, {
                etapaAtendimento: { status: r.status },
              }).length > 0
            );
          }
        }
      }),
      'prioridade'
    );
    this.state = {
      showAnamnese: false,
      idPaciente: 0,
      showNewPaciente: false,
      showConfirmPaciente: false,
      showPacienteConsultaAvulsa: false,
      nameNewPaciente: '',
      horaNewPaciente: '',
      refRow: '',
      paciente: defaultPaciente,
      openModalLocal: false,
      abreInputEncaixe: false,
      showConsultaAvulsa: false,
      showPacientesAtender: false,
      showFilaAtendimento: false,
      showModalLocalRecepcao: false,
      showTriagem: false,
      listLocalAtendimento: [],
      edicaoDeConsulta: false,
      inputLocalRequired: false,
      filaAtendimento,
    };
    const { itemHorario, pacienteOnline, estabelecimento } = props;
    if (itemHorario && pacienteOnline) {
      this.handleSelectPaciente(
        pacienteOnline,
        itemHorario.horario,
        itemHorario.medico,
        itemHorario.estabelecimentoAtendimento,
        estabelecimento,
        _.get(itemHorario, 'atendeTelemedicina')
      );
    }
    if (userService.isMedico()) {
      this.verificaEstabelecimentoPendente();
    }
    if (userService.isSecretaria) {
      let localRecepcao = this.getLocalRecepcao();
    }
  }
  componentDidMount() {
    if (!userService.isMedico()) {
      estabelecimentoService
        .findLocalAtendimento(userService.getExtension())
        .then((r) => {
          this.setState({ listLocalAtendimento: r.data });
        });
      window.addEventListener('scroll', this.listenScrollEvent);
    }
  }

  listenScrollEvent = (e) => {
    let lista = _.get(this.props.user, 'listLocalAtendimentoRecepcao');
    if (window.scrollY > 200 && lista && lista.length < 1) {
      if (this.state.inputLocalRequired === false) {
        this.setState({ inputLocalRequired: true });
      }
    } else if (this.state.inputLocalRequired === true) {
      this.setState({ inputLocalRequired: false });
    }
  };

  verificaEstabelecimentoPendente = () => {
    medicoService.findEstabelecimentoPagamentoPendente().then((response) => {
      this.setState({ estabelecimentosPendentes: response.data });
    });
  };

  handleNewOptionClick = (
    nome: any,
    tipo,
    horario,
    medico,
    estabelecimentoAtendimento,
    estabelecimento,
    idExame
  ) => {
    let paciente = defaultPaciente;
    paciente.nome = nome;
    paciente.primeiraConsulta = true;
    paciente.horario = horario;
    paciente.tipo = tipo;
    paciente.especialidade = null;
    let cObrigatorio = _.filter(this.props.camposObrigatorios, function (co) {
      return co.idEstabelecimento == estabelecimento.id;
    });
    _.set(paciente, 'camposObrigatorios', cObrigatorio[0]);
    _.set(paciente, 'exame', idExame);
    this.setState({
      paciente,
      showNewPaciente: true,
      nameNewPaciente: nome,
      horaNewPaciente: horario,
      medico,
      estabelecimentoAtendimento,
      estabelecimento,
    });
  };

  handleSelectPaciente = (
    paciente: any,
    horario,
    medico,
    estabelecimentoAtendimento,
    estabelecimento,
    atendeTelemedicina
  ) => {
    this.props.loading(true);
    marcacaoService
      .consultaHoje(estabelecimentoAtendimento.id, paciente.id, this.props.date)
      .then(
        (response) => {
          let temConsultahoje = response.data;
          if (temConsultahoje) {
            this.props.warning({
              message: `Já existe uma marcação para ${_.get(
                paciente,
                'nome'
              )} com ${_.get(medico, 'apelido')} no dia de hoje`,
            });
            this.props.loading(false);
          }

          marcacaoService
            .primeiraConsulta(
              estabelecimentoAtendimento.id,
              paciente.id,
              this.props.date,
              horario + ':00'
            )
            .then(
              (response) => {
                let primeiraConsulta = response.data.primeiraConsulta;
                let primeiraConsultaDisabled = response.data.disable;
                let limiteDiarioAtingido = response.data.limiteDiarioAtingido;
                if (primeiraConsulta && limiteDiarioAtingido) {
                  this.props.error({
                    message:
                      'Não foi possível realizar o agendamento, para PRIMEIRA CONSULTA entre em contato com a clínica',
                  });
                  this.props.loading(false);
                } else {
                  userService.doGet(paciente.id).then(
                    (response) => {
                      let tipoConsulta = _.get(paciente, 'tipoConsulta');
                      let tipo = _.get(paciente, 'tipo');
                      let newPaciente = response.data.data;
                      _.set(newPaciente, 'tipoConsulta', tipoConsulta);
                      _.set(newPaciente, 'primeiraConsulta', primeiraConsulta);
                      _.set(newPaciente, 'marcacaoRecorrente', false);
                      _.set(
                        newPaciente,
                        'primeiraConsultaDisabled',
                        primeiraConsultaDisabled
                      );
                      _.set(newPaciente, 'horario', horario);
                      _.set(
                        newPaciente,
                        'atendeTelemedicina',
                        atendeTelemedicina
                      );
                      _.set(newPaciente, 'tipo', tipo);
                      let cObrigatorio = _.filter(
                        this.props.camposObrigatorios,
                        function (co) {
                          return co.idEstabelecimento == estabelecimento.id;
                        }
                      );
                      _.set(newPaciente, 'camposObrigatorios', cObrigatorio[0]);

                      this.setState(
                        {
                          paciente: newPaciente,
                          showConfirmPaciente: true,
                          horaNewPaciente: horario,
                          medico,
                          estabelecimentoAtendimento,
                          estabelecimento,
                          novoStatus: null,
                        },
                        () => {}
                      );
                      this.props.loading(false);
                    },
                    (error) => {
                      console.log(error);
                      this.props.loading(false);
                    }
                  );
                }
              },
              (error) => {
                this.props.loading(false);
              }
            );
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
  };

  handleSelectPacienteConsultaAvulsa = (values) => {
    let paciente = _.get(values, 'paciente');
    let horario = dateHelper.getHourMinuteNow();
    let estabelecimentoAtendimento = _.get(values, 'estabelecimentoMedico');
    let medico = _.get(estabelecimentoAtendimento, 'medico');
    let estabelecimento = _.get(estabelecimentoAtendimento, 'estabelecimento');

    this.props.loading(true);
    userService.doGet(paciente.id).then(
      (response) => {
        let tipoConsulta = _.get(paciente, 'tipoConsulta');
        let tipo = objectsConstants.TIPO_MARCACAO;
        let newPaciente = response.data.data;
        _.set(newPaciente, 'tipoConsulta', tipoConsulta);
        _.set(newPaciente, 'primeiraConsulta', false);
        _.set(newPaciente, 'primeiraConsultaDisabled', false);
        _.set(newPaciente, 'horario', horario);
        _.set(newPaciente, 'atendeTelemedicina', false);
        _.set(newPaciente, 'tipo', tipo);
        _.set(newPaciente, 'requiredAddres', true);
        let cObrigatorio = _.filter(
          this.props.camposObrigatorios,
          function (co) {
            return co.idEstabelecimento == estabelecimento.id;
          }
        );
        _.set(newPaciente, 'camposObrigatorios', cObrigatorio[0]);

        this.setState(
          {
            paciente: newPaciente,
            showPacienteConsultaAvulsa: true,
            horaNewPaciente: horario,
            medico,
            estabelecimentoAtendimento,
            estabelecimento,
            novoStatus: null,
          },
          () => {}
        );
        this.props.loading(false);
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  handleNewOptionClickConsultaAvulsa = (values) => {
    let paciente = defaultPaciente;
    paciente.nome = _.get(values, 'nome');
    paciente.primeiraConsulta = true;
    paciente.horario = dateHelper.getHourMinuteNow();
    paciente.tipo = objectsConstants.TIPO_MARCACAO;
    _.set(paciente, 'requiredAddres', true);
    let estabelecimentoAtendimento = _.get(values, 'estabelecimentoMedico');
    let medico = _.get(estabelecimentoAtendimento, 'medico');
    let estabelecimento = _.get(estabelecimentoAtendimento, 'estabelecimento');
    let cObrigatorio = _.filter(this.props.camposObrigatorios, function (co) {
      return co.idEstabelecimento == estabelecimento.id;
    });
    _.set(paciente, 'camposObrigatorios', cObrigatorio[0]);

    this.setState({
      paciente,
      showPacienteConsultaAvulsa: true,
      nameNewPaciente: paciente.nome,
      horaNewPaciente: paciente.horario,
      medico,
      estabelecimentoAtendimento,
      estabelecimento,
    });
  };

  handleSelectPacienteExame = (
    paciente: any,
    horario,
    estabelecimentoAtendimento,
    estabelecimento,
    idExame
  ) => {
    this.props.loading(true);
    userService.doGet(paciente.id).then(
      (response) => {
        let tipoConsulta = _.get(paciente, 'tipoConsulta');
        let tipo = _.get(paciente, 'tipo');
        let newPaciente = response.data.data;
        _.set(newPaciente, 'tipoConsulta', tipoConsulta);
        _.set(newPaciente, 'primeiraConsulta', false);
        _.set(newPaciente, 'horario', horario);
        _.set(newPaciente, 'tipo', tipo);
        _.set(newPaciente, 'exame', idExame);
        // _.set(newPaciente, 'dataConsulta', this.props.date);
        let cObrigatorio = _.filter(
          this.props.camposObrigatorios,
          function (co) {
            return co.idEstabelecimento == estabelecimento.id;
          }
        );
        _.set(newPaciente, 'camposObrigatorios', cObrigatorio[0]);
        this.setState(
          {
            paciente: newPaciente,
            showConfirmPaciente: true,
            horaNewPaciente: horario,
            estabelecimentoAtendimento,
            estabelecimento,
            novoStatus: null,
          },
          () => {}
        );
        this.props.loading(false);
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  handleMarcacaoPaciente = (paciente) => {
    const { medico, estabelecimentoAtendimento, horaNewPaciente } = this.state;
    this.doSaveMarcacao(
      paciente,
      horaNewPaciente,
      paciente.medico ? paciente.medico : medico,
      estabelecimentoAtendimento
    );
    this.cancelShowNewPaciente();
  };

  handleSavePacienteAndChange  = async (paciente)  => {
    const { agendamento, novoStatus, edicaoDeConsulta } = this.state;
    let newPaciente = _.cloneDeep(paciente);
      if (agendamento) {
        let convenio = _.get(paciente, 'pacienteConvenios[0].convenio');
        let dadosAutorizacao;
        if (
           _.get(paciente, 'dataAutorizacao') ||
           _.get(paciente, 'senha') ||
           _.get(paciente, 'dataValidadeSenha') ||
           _.get(paciente, 'numeroGuiaOperador')
        ) {
          dadosAutorizacao = {
            dataAutorizacao: _.get(paciente, 'dataAutorizacao'),
            senha: _.get(paciente, 'senha'),
            dataValidadeSenha: _.get(paciente, 'dataValidadeSenha'),
            numeroGuiaOperador: _.get(paciente, 'numeroGuiaOperador'),
          };
          }
        this.props.onStatusChanged(
          agendamento.idMarcacao,
          novoStatus,
          paciente.tipoAtendimento,
          convenio,
          paciente.tipoConsulta,
          paciente.observacoes,
          paciente.especialidade,
          paciente.medico,
          edicaoDeConsulta ? null : paciente.etapaAtendimento,
          dadosAutorizacao,
          this.state.senha,
          paciente.listMidias,
          paciente.telemedicina
        );
        if (this.state.senha) {
          this.setState({ senha: null });
        }
        this.setState({ agendamento: null });
      }   

      else {
        this.handleMarcacaoPaciente(paciente);
      }
      this.props.setPaciente(newPaciente);
      this.toggleConfirmPacientes();
  };

  handleSaveTriagem = () => {
    const { marcacao } = this.state;
    const idMarcacao = _.cloneDeep(marcacao.idMarcacao);

    this.setState(
      { marcacao: null, showTriagem: !this.state.showTriagem },
      () => {
        this.props.onStatusChanged(
          idMarcacao,
          objectsConstants.AGUARDANDO_ATENDIMENTO
        );
      }
    );
  };

  handleSavePacienteAndChangeConsultaAvulsa = (paciente) => {
    const { medico, estabelecimentoAtendimento, horaNewPaciente, agendamento } =
      this.state;
    let dataConsulta = moment(this.props.date);
    let fkCovenio = null;
    let planoConvenio = null;
    if (
      _.get(paciente, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      fkCovenio = _.get(paciente, 'pacienteConvenios[0].convenio.id');
      planoConvenio = _.get(paciente, 'pacienteConvenios[0].planoConvenio');
    }

    let tipoConsulta = _.get(paciente, 'tipoConsulta');
    let observacoes = _.get(paciente, 'observacoes');
    let tipo = _.get(paciente, 'tipo');

    marcacaoService
      .doSave({
        horaString: horaNewPaciente,
        dataConsulta: dataConsulta.format('YYYY-MM-DD'),
        fkPaciente: paciente.id,
        fkMedico: _.get(medico, 'id', _.get(paciente, 'medico.id')),
        fkEstabelecimentoAtendimento: estabelecimentoAtendimento.id,
        tipoAtendimento: paciente.tipoAtendimento,
        fkCovenio,
        paciente,
        telemedicina: paciente.telemedicina,
        tipoConsulta,
        observacoes,
        tipo,
        planoConvenio,
        especialidade: paciente.especialidade,
        consultaAvulsa: true,
      })
      .then(
        (response) => {
          let marcacaoResponse = response.data.data;
          this.props.onStatusChanged(
            marcacaoResponse.id,
            objectsConstants.EM_ATENDIMENTO,
            paciente.tipoAtendimento,
            paciente.convenio,
            paciente.tipoConsulta,
            observacoes
          );
          this.props.setPaciente(paciente);
          this.togglePacientesConsultaAvulsa();
          this.handleAnamneseClick(paciente.id, marcacaoResponse.id);
        },
        (erros) => {
          console.log(erros);
          this.props.error({ message: `Erro ao atender fora da agenda` });
        }
      );

    // this.props.setPaciente(paciente);
    // this.togglePacientesConsultaAvulsa();
    // this.handleAnamneseClick(paciente.id);
  };

  doSaveMarcacao = (
    paciente: paciente,
    horaString: string,
    medico: medico,
    estabelecimentoAtendimento: estabelecimentoAtendimento
  ) => {
    let dataConsulta = moment(this.props.date);
    let fkCovenio = null;
    let planoConvenio = null;
    if (
      _.get(paciente, 'tipoAtendimento') ===
      objectsConstants.TIPO_ATENDIMENTO_CONVENIO
    ) {
      fkCovenio = _.get(paciente, 'pacienteConvenios[0].convenio.id');
      planoConvenio = _.get(paciente, 'pacienteConvenios[0].planoConvenio');
    }

    let tipoConsulta = _.get(paciente, 'tipoConsulta');
    let observacoes = _.get(paciente, 'observacoes');
    let recorrencia = {
      periodicidade: _.get(paciente, 'periodicidade'),
      dataFinal: _.get(paciente, 'dataFinal'),
    };

    let tipo = _.get(paciente, 'tipo');
    this.props.onMarcacaoCreated({
      horaString,
      dataConsulta: dataConsulta.format('YYYY-MM-DD'),
      fkPaciente: paciente.id,
      fkMedico: _.get(medico, 'id', _.get(paciente, 'medico.id')),
      fkEstabelecimentoAtendimento: estabelecimentoAtendimento.id,
      tipoAtendimento: paciente.tipoAtendimento,
      fkCovenio,
      paciente,
      telemedicina: paciente.telemedicina,
      tipoConsulta,
      observacoes,
      tipo,
      planoConvenio,
      especialidade: paciente.especialidade,
      senhaAtendimento: this.state.senha,
      listMidias: _.get(paciente, 'listMidias'),
      listDiasRecorrentes: paciente.listDiasRecorrentes,
      recorrencia,
    });
    if (this.state.senha) {
      this.setState({ senha: null });
    }
  };

  cancelShowNewPaciente = () => {
    this.setState({
      paciente: defaultPaciente,
      showNewPaciente: false,
      nameNewPaciente: '',
    });
  };

  gotoMedicoPage = (idMedico) => {
    let idUser = this.props.user.id;
    let url;

    if (idMedico == idUser) {
      url = `/perfil/${idMedico}`;
    } else {
      url = `/medico/${idMedico}`;
    }
    this.props.history.push({
      pathname: url,
      state: { clickBackUrl: true },
    });
  };

  countMarcacoes = (marcacoes?: any) => {
    if (!marcacoes) {
      return 0;
    }
    return marcacoes.length;
  };

  findMarcacoes = (marcacoes?: any, itemHorario: any, medico: any) => {
    if (!marcacoes) {
      return null;
    }

    return marcacoes.find(function (v) {
      if (_.get(itemHorario, 'medico') && !_.get(itemHorario, 'exame')) {
        if (
          _.get(itemHorario, 'paciente') &&
          _.get(v, 'paciente') &&
          _.get(itemHorario, 'paciente.id') &&
          _.get(v, 'paciente.id')
        ) {
          return (
            _.get(v, 'horario') === _.get(itemHorario, 'horario') &&
            _.get(v, 'medico.id') === _.get(itemHorario, 'medico.id') &&
            _.get(v, 'paciente.id') === _.get(itemHorario, 'paciente.id')
          );
        }
        return (
          _.get(v, 'horario') === _.get(itemHorario, 'horario') &&
          _.get(v, 'medico.id') === _.get(itemHorario, 'medico.id')
        );
      }
      if (_.get(itemHorario, 'exame')) {
        if (_.get(v, 'exame')) {
          return (
            _.get(v, 'horario') === _.get(itemHorario, 'horario') &&
            _.get(v, 'exame.id') === _.get(itemHorario, 'exame.id')
          );
        }
      }
    });
  };

  createHorarioTable = (agendas?: Array) => {
    let horarios = [];
    if (!agendas) {
      return [];
    }

    agendas.sortBy('horario').map((itemHorario) => {
      horarios.push(itemHorario.hora);
    });

    horarios = Array.from(new Set(horarios));

    return horarios;
  };

  togglePacientes = () => {
    this.setState(({ openPaciente }) => ({ openPaciente: !openPaciente }));
  };

  toggleConfirmPacientes = () => {
    this.setState(({ showConfirmPaciente }) => ({
      showConfirmPaciente: !showConfirmPaciente,
    }));
  };

  togglePacientesConsultaAvulsa = () => {
    this.setState(({ openPacienteConsultaAvulsa }) => ({
      openPacienteConsultaAvulsa: !openPacienteConsultaAvulsa,
    }));
  };

  togglePacientesAtender = () => {
    this.setState(({ showPacientesAtender }) => ({
      showPacientesAtender: !showPacientesAtender,
    }));
  };

  toggleFilaAtendimento = () => {
    this.setState(({ showFilaAtendimento }) => ({
      showFilaAtendimento: !showFilaAtendimento,
    }));
  };

  onChange = (estabelecimentoId, value, medico) => {
    this.props.loading(true);
    estabelecimentoService.checkInLocal(value, medico).then(
      (response) => {
        let newUser = _.cloneDeep(this.props.user);
        this.props.loading(false);
        if (userService.isMedico()) {
          let lista = _.get(newUser, 'listLocalAtendimento');
          _.remove(lista, function (item) {
            return item.estabelecimento.id === estabelecimentoId;
          });
          lista.push(value);
        }
        this.props.refreshUser({ user: newUser });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getLocalRecepcao = (idEstabelecimento) => {
    let newUser = _.cloneDeep(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
    if (lista) {
      for (let i = 0; i < lista.length; i++) {
        const item = lista[i];
        if (item.estabelecimento.id === userService.getExtension()) {
          return item;
        }
      }
    }
  };
  onChangeLocalRecepcao = (estabelecimento, local, value, status) => {
    estabelecimentoService.checkInRecepcionistaLocal(local).then(
      (response) => {
        let newUser = _.cloneDeep(this.props.user);
        let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
        _.remove(lista, function (item) {
          return item.estabelecimento.id === estabelecimento.id;
        });
        lista.push(local);
        this.props.refreshUser({ user: newUser });
        this.handleConfirmPaciente(value, status, estabelecimento);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  onChangeSelectLocalRecepcao = (estabelecimentoId, local) => {
    estabelecimentoService.checkInRecepcionistaLocal(local).then(
      (response) => {
        let newUser = _.cloneDeep(this.props.user);
        let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
        _.remove(lista, function (item) {
          return item.estabelecimento.id === estabelecimentoId;
        });
        lista.push(local);
        this.props.refreshUser({ user: newUser });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  limpa = (estabelecimentoId) => {
    let value = {};
    let newUser = _.cloneDeep(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimento');
    _.remove(lista, function (item) {
      return item.estabelecimento.id === estabelecimentoId;
    });
    let listaRecepcao = _.get(newUser, 'listLocalAtendimentoRecepcao');
    _.remove(listaRecepcao, function (item) {
      return item.estabelecimento.id === estabelecimentoId;
    });
    // lista.push(value);
    this.props.refreshUser({ user: newUser });
  };

  getLocal = (idEstabelecimento, medico) => {
    if (medico && medico.localAtendimento) {
      return { nome: medico.localAtendimento, id: medico.idLocalAtendimento };
    }
    let newUser = _.cloneDeep(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimento');
    if (lista) {
      for (let i = 0; i < lista.length; i++) {
        const item = lista[i];
        if (item.estabelecimento.id === idEstabelecimento) {
          return item;
        }
      }
    }
  };
  getLocalRecepcao = (idEstabelecimento) => {
    let newUser = _.cloneDeep(this.props.user);
    let lista = _.get(newUser, 'listLocalAtendimentoRecepcao');
    if (lista) {
      for (let i = 0; i < lista.length; i++) {
        const item = lista[i];
        if (item.estabelecimento.id === idEstabelecimento) {
          return item;
        }
      }
    }
  };

  verificaAgendaExpirando = (dataFinal, idMedico) => {
    if (dateHelper.isBeforeDays(dataFinal)) {
      return (
        <button
          type="button"
          className="btn btn-link-danger"
          onClick={() => {
            this.gotoMedicoPage(idMedico);
          }}
        >
          Data final da agenda expirando!
        </button>
      );
    }
    return null;
  };

  getHeader = (usuario, nome, description, key, dataFinal, idMedico) => {
    return (
      <React.Fragment>
        <div className="col-12 col-lg-7 d-flex flex-column flex-lg-row photo-name">
          {usuario && <Avatar user={usuario} editable={false}></Avatar>}
          <div className="title d-flex flex-column justify-content-center">
            <div className="d-block d-sm-flex">
              <p className="name mr-2">{nome ? nome : ''}</p>
              <span className="d-flex flex-row align-items-center justify-content-center">
                {!userService.isMedico() &&
                !userService.isPaciente() &&
                this.props.loggedIn
                  ? legendHelper.createLegendRecomendacao(
                      _.get(usuario, 'recomendacoesSecretaria'),
                      key
                    )
                  : ''}
                {!userService.isPaciente() &&
                  this.props.loggedIn &&
                  this.verificaAgendaExpirando(dataFinal, idMedico)}
              </span>
            </div>
            <p className="info">{description ? description : ''}</p>
            {!userService.isPaciente() && (
              <p className="info">
                <b>{_.get(usuario, 'localAtendimento')}</b>
              </p>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  getHeaderExame = (exame) => {
    return (
      <React.Fragment>
        <div className="col-12 col-lg-7 d-flex flex-column flex-lg-row photo-name">
          {/* <Avatar user={usuario} editable={false}></Avatar> */}
          <div className="title d-flex flex-column justify-content-center">
            <p className="name">{_.get(exame, 'nome')}</p>
            {/* <p className="info">{description}</p> */}
          </div>
        </div>
      </React.Fragment>
    );
  };

  handleConfirmPaciente = (agendamento, status, estabelecimento) => {
    this.props.loading(true);
    userService.doGet(agendamento.paciente.id).then(
      (response) => {
        let paciente = response.data.data;
        paciente.requiredAddres = true;
        paciente.requiredMedico = true;
        paciente.observacoes = _.get(agendamento, 'observacoes');
        paciente.agendamento = agendamento;
        paciente.medico = _.get(agendamento, 'medico');
        paciente.horario = _.get(agendamento, 'horario');
        paciente.tipoConsulta = _.get(agendamento, 'tipoConsulta');
        paciente.tipoAtendimento = _.get(agendamento, 'tipoAtendimento');
        paciente.especialidade = _.get(agendamento, 'especialidade');
        paciente.exame = _.get(agendamento, 'exame.id');
        paciente.atendeTelemedicina = _.get(agendamento, 'atendeTelemedicina');
        paciente.telemedicina = _.get(agendamento, 'telemedicina');
        paciente.primeiraConsulta =
          _.get(agendamento, 'tipoConsulta') ===
          objectsConstants.TIPO_CONSULTA_PRIMEIRA
            ? true
            : false;
        let cObrigatorio = _.filter(
          this.props.camposObrigatorios,
          function (co) {
            return co.idEstabelecimento == estabelecimento.id;
          }
        );
        _.set(paciente, 'camposObrigatorios', cObrigatorio[0]);
        agendamento.paciente = paciente;
        this.setState({
          paciente,
          agendamento,
          showConfirmPaciente: true,
          estabelecimentoAtendimento: agendamento.estabelecimentoAtendimento,
          edicaoDeConsulta: agendamento.status === status ? true : false,
          novoStatus: status,
          estabelecimento: estabelecimento,
        });
        this.props.loading(false);
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
      }
    );
  };

  cancelShowConfirmPaciente = () => {
    let state = _.cloneDeep(this.state);
    state.showConfirmPaciente = false;
    state.novoStatus = null;
    state.agendamento = null;
    if (
      this.state.horaNewPaciente &&
      this.state.paciente.tipo === objectsConstants.TIPO_MARCACAO
    ) {
      this.getRef(`compRef_${this.state.horaNewPaciente}`).clearPacienteState();
    }

    this.setState(state);
    this.toggleConfirmPacientes();
  };

  cancelShowPacienteConsultaAvulsa = () => {
    let state = _.cloneDeep(this.state);
    state.showPacienteConsultaAvulsa = false;
    state.novoStatus = null;
    state.agendamento = null;
    this.setState(state);
    this.togglePacientesConsultaAvulsa();
  };

  cancelShowFilaAtendimento = () => {
    let state = _.cloneDeep(this.state);
    state.showFilaAtendimento = false;
    this.setState(state);
    this.toggleFilaAtendimento();
  };

  toggleModalLocal = (estabelecimento, idMarcacao, status) => {
    this.getRef(`ref_${estabelecimento.id}`).toogleModal(idMarcacao, status);
  };
  toggleModalLocalRecepcao = (estabelecimento, value, status) => {
    this.getRef(`ref_modal_recepcao${estabelecimento.id}`).toogleModal(
      estabelecimento,
      value,
      status
    );
  };

  salvarModalLocal = (idEstabelecimento, local, idMarcacao, status, medico) => {
    this.onChange(idEstabelecimento, local, medico);
    this.props.onStatusChanged(idMarcacao, status);
  };
  salvarModalLocalRecepcao = (estabelecimento, local, value, status) => {
    this.onChangeLocalRecepcao(estabelecimento, local, value, status);
  };

  statusChange = (value, status, confirmar, estabelecimento) => {
    if (confirmar) {
      if (
        userService.isSecretaria() &&
        status === objectsConstants.AGUARDANDO_ATENDIMENTO &&
        this.getLocalRecepcao(estabelecimento.id) === undefined
      ) {
        this.toggleModalLocalRecepcao(estabelecimento, value, status);
      } else {
        this.handleConfirmPaciente(value, status, estabelecimento);
      }
    } else {
      //Se chamou e não tem uma sla apara o estabelecimento
      if (
        status === objectsConstants.EM_CHAMADA &&
        this.getLocal(estabelecimento.id) === undefined
      ) {
        this.toggleModalLocal(estabelecimento, value.idMarcacao, status);
      } else {
        if (status === objectsConstants.CANCELADO) {
          this.verificaListaEncaixe(value);
        }
        this.props.onStatusChanged(value.idMarcacao, status);
      }
    }
  };
  toggleTriagem = (marcacao) => {
    this.props.loading(true);
    if (marcacao) {
      userService.doGet(marcacao.paciente.id).then((response) => {
        let paciente = response.data.data;
        this.setState({
          marcacao,
          showTriagem: !this.state.showTriagem,
          paciente,
        });
        this.props.loading(true);
      });
    } else {
      this.setState({ marcacao, showTriagem: !this.state.showTriagem });
    }
  };
  verificaListaEncaixe = (value) => {
    if (_.get(value, 'tipo') === objectsConstants.TIPO_MARCACAO) {
      if (value.listaEncaixe && value.listaEncaixe.length > 0) {
        // primeiro encaixe assume marcacao
      }
    }
  };
  setRef = (key, ref) => {
    this[key] = ref;
  };

  getRef = (key) => {
    return this[key];
  };

  filterAgendaBydate = (dataFilter) => {
    let _this = this;
    let { filtro } = _this.props;
    _this.props.loading(true);
    let _dataStart = dataFilter;
    let _dataEnd = _.cloneDeep(dataFilter);
    _dataEnd = _dataEnd.setHours(23, 59, 0, 0);
    _.set(
      filtro,
      'dataStart',
      moment(_dataStart).format('YYYY-MM-DDTHH:mm:ss')
    );
    _.set(filtro, 'dataEnd', moment(_dataEnd).format('YYYY-MM-DDTHH:mm:ss'));
    filtro.medico = _.get(filtro, 'estabelecimentoAtendimento.medico');

    agendaService.findHorariosConsulta(filtro).then(
      (json) => {
        _this.props.loading(false);
        _this.props.setAgendaDoDia({
          agendaDoDia: json.data,
          dataAgendaDia: _dataStart,
        });
        return json.data;
      },
      (error) => {
        _this.props.error({
          message: 'Não foi possível atualizar a agenda.',
        });
        _this.props.loading(false);
      }
    );
  };

  getAgendasByDate = () => {
    const { date, agendas, agendaDoDia, dataAgendaDia, filtro } = this.props;

    if (dateHelper.isBeforeNow(date)) {
      if (agendaDoDia && dataAgendaDia === date) {
        let cache = new CacheAgenda(filtro);
        cache.agenda = agendaDoDia;

        return cache.filtrar(filtro);
      } else {
        if (!this.props.isAgendamentoOnline) {
          this.filterAgendaBydate(date);
        }
        return [];
      }
    }
    return getAgendasDay(agendas, date);
  };
  iniciarAtendimento = (senha, cpf) => {
    const { date, agendas, agendaDoDia, dataAgendaDia, filtro } = this.props;
    this.setState({ senha }, () => {
      let agendaPaciente = _.filter(this.getAgendasByDate(), (a) => {
        return (
          a.paciente &&
          (a.paciente.cpfCnpj === cpf.replace(/[^0-9]/g, '') ||
            a.paciente.cpfCnpj === cpf)
        );
      });
      if (agendaPaciente && agendaPaciente.length > 0) {
        const element = document.getElementById(agendaPaciente[0].medico.nome);
        setTimeout(() => {
          window.scrollBy(0, element.offsetTop);
        }, 800);
        this.props.warning({
          message: `Paciente agendado com ${agendaPaciente[0].medico.apelido}`,
        });
      } else {
        this.props.warning({ message: `Paciente sem agenda para hoje.` });
      }
    });
  };
  iniciarAtendimentoMarcacao = (cpf) => {
    const { date, agendas, agendaDoDia, dataAgendaDia, filtro } = this.props;

    let agendaPaciente = _.filter(this.getAgendasByDate(), (a) => {
      return (
        a.paciente &&
        (a.paciente.cpfCnpj === cpf.replace(/[^0-9]/g, '') ||
          a.paciente.cpfCnpj === cpf)
      );
    });
    if (agendaPaciente && agendaPaciente.length > 0) {
      const element = document.getElementById(agendaPaciente[0].medico.nome);
      setTimeout(() => {
        window.scrollBy(0, element.offsetTop);
      }, 800);
      if (userService.isMedico()) {
        this.props.warning({
          message: `Paciente agendado as ${agendaPaciente[0].horario}`,
        });
      } else {
        this.props.warning({
          message: `Paciente agendado com ${agendaPaciente[0].medico.apelido}`,
        });
      }
    } else {
      this.props.warning({ message: `Paciente sem agenda para hoje.` });
    }
  };
  remarcar = (marcacao) => {
    let filtro = {};
    if (this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS) {
      _.set(filtro, 'estabelecimentoAtendimento.medico', marcacao.medico);
      _.set(filtro, 'medicoList', [marcacao.medico]);
    } else if (marcacao.tipoAgenda === objectsConstants.TIPO_AGENDA_EXAMES) {
      _.set(filtro, 'exame', marcacao.exame);
    }
    _.set(marcacao, 'tipoAgenda', this.props.tipoAgenda);
    this.props.changeFilter(filtro);
    this.props.receiveViewHorario(false);
    this.props.history.push({
      pathname: '/reagendamento',
      state: { marcacao: marcacao },
    });
  };

  render() {
    const {
      date,
      dataAgendaDia,
      filtro,
      medico,
      agendas,
      user,
      ...otherProps
    } = this.props;
    let newUser = _.cloneDeep(this.props.user);
    let agendaDoDia = null;

    agendaDoDia = this.getAgendasByDate(
      date,
      agendas,
      agendaDoDia,
      dataAgendaDia,
      filtro
    );
    let listPerfil = _.get(this.props.user, 'role.role.listaPerfil', []);
    let isSecretaria = userService.isSecretaria();
    let listLocaoAtendimento = _.get(
      this.props.user,
      'listLocalAtendimentoRecepcao',
      []
    );
    let permissaoSecretaria = _.filter(listPerfil, (p) => {
      if (isSecretaria && p.etapaAtendimento.local) {
        return (
          _.filter(listLocaoAtendimento, (local) => {
            return local.local.indexOf(p.etapaAtendimento.local) >= 0;
          }).length > 0
        );
      }
      return true;
    });
    let filaAtendimento = _.maxBy(
      _.filter(objectsConstants.ROLE_STATUS_AGENDA, (r) => {
        if (r.permission) {
          if (!isSecretaria) {
            return (
              _.filter(
                _.get(this.props.user, 'role.role.listRolePermission', []),
                { casoDeUso: { nome: r.permission } }
              ).length > 0
            );
          } else {
            return (
              _.filter(permissaoSecretaria, {
                etapaAtendimento: { status: r.status },
              }).length > 0
            );
          }
        }
      }),
      'prioridade'
    );
    let marcacoes = agendaDoDia ? _.filter(agendaDoDia, 'paciente') : [];

    let marcacoesAguardandoAtendimento = _.filter(marcacoes, (m) => {
      if (filaAtendimento) {
        return (
          filaAtendimento.status === m.status &&
          (!isSecretaria ||
            !m.localEtapaAtendimento ||
            _.filter(listLocaoAtendimento, (local) => {
              return local.local.indexOf(m.localEtapaAtendimento) >= 0;
            }).length > 0)
        );
      }
      return false;
    });

    let estabelecimentos = _.groupBy(agendaDoDia, function (agenda) {
      return _.get(agenda, 'estabelecimento.nome');
    });

    let filterMarcacoes = _.orderBy(
      _.filter(marcacoes, { status: 'AGUARDANDO_ATENDIMENTO' }, []),
      ['horario', 'HoraChegada']
    );
    let filterMarcacoesSecretaria = _.orderBy(
      _.filter(marcacoes, { status: 'AGUARDANDO_RECEPCAO' }, []),
      ['horario', 'HoraChegada']
    );

    let _this = this;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={
            this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_CONSULTAS
              ? objectsConstants.TITULO_PAGINA_AGENDA
              : objectsConstants.TITULO_PAGINA_EXAMES
          }
          subTitle=""
          backUrl={this.props.backUrl}
          canBack={true}
          onClickBack={this.props.onGoBack}
        />

        {isEmptyObject(estabelecimentos) && (
          <React.Fragment>
            <div className="row w-100 justify-content-center">
              <div className="row doctor cardInfo">
                <div className="d-flex justify-content-center">
                  <img
                    src={IconAgendaOff}
                    alt="Imagem Link agendamento online"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <p className="title">
                    {dateHelper.isBeforeNow(date)
                      ? 'Nenhum horário estava disponível nessa data'
                      : 'Nenhum profissional de saúde disponível'}
                  </p>
                  <p className="instruction">
                    {dateHelper.isBeforeNow(date)
                      ? `Neste
      momento não há nenhum profissional de saúde com agenda aberta
      para este data.`
                      : `Pesquise por algum outro profissional ou especialidade para encontrar uma data e horário disponível.`}
                  </p>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {!isEmptyObject(estabelecimentos) && (
          <React.Fragment>
            <div className="local-atendimento-fixed d-flex w-100 justify-content-between align-items-center">
              <React.Fragment>
                {dateHelper.isToday(this.props.date) && (
                  <React.Fragment>
                    {userService.isSecretaria() && (
                      <React.Fragment>
                        <div className="col-3">
                          <InputViewEdit
                            component={FormSelectInput}
                            type="text"
                            name="recepcao"
                            label=""
                            placeholder="Selecione seu local de atendimento"
                            onChange={(name, value) => {
                              if (value) {
                                this.onChangeSelectLocalRecepcao(
                                  userService.getExtension(),
                                  value
                                );
                              } else {
                                this.limpa(userService.getExtension());
                              }
                            }}
                            noSize={true}
                            viewMode={false}
                            id="recepcao"
                            returnFullObject={true}
                            service={
                              estabelecimentoService.findLocalAtendimentoRecepcao
                            }
                            labelKey={'nome'}
                            valueKey={'id'}
                            value={this.getLocalRecepcao(
                              userService.getExtension()
                            )}
                            required={true}
                            parent={userService.getExtension()}
                            clearable={true}
                            className={`${
                              this.state.inputLocalRequired ? 'is-invalid' : ''
                            } flex-fill`}
                          />
                        </div>

                        <ModalLocalRecepcao
                          ref={(r) =>
                            this.setRef(
                              `ref_modal_recepcao${userService.getExtension()}`,
                              r
                            )
                          }
                          name="recepcao"
                          target={'recapcao'}
                          getLocalRecepcao={this.getLocalRecepcao}
                          estabelecimento={userService.getExtension()}
                          callbackOnSave={this.salvarModalLocalRecepcao}
                          {...otherProps}
                        />
                      </React.Fragment>
                    )}
                    {(userService.isAdministrador() ||
                      userService.isEnfermeira()) && (
                      <div className="col-3"></div>
                    )}

                    {userService.isMedico() &&
                      !dateHelper.isToday(this.props.date) && (
                        <div className="col-3"></div>
                      )}
                    {(userService.isAdministrador() ||
                      (userService.isSecretaria() &&
                        permissaoSecretaria &&
                        permissaoSecretaria.length > 0 &&
                        _.filter(permissaoSecretaria, {
                          etapaAtendimento: {
                            status: objectsConstants.MARCADO,
                          },
                        }).length > 0)) && (
                      <FilaAtendimentoButton
                        filterMarcacoesSecretaria={filterMarcacoesSecretaria}
                        fila={_.filter(this.props.fila, (f) => {
                          return (
                            f &&
                            (!f.status ||
                              (f.status != objectsConstants.FILA_BLOQUEADA &&
                                f.status != objectsConstants.FILA_INATIVA))
                          );
                        })}
                        user={this.props.user}
                        statusChange={this.statusChange}
                        onChange={this.iniciarAtendimento}
                        loading={this.props.loading}
                        error={this.props.error}
                        success={this.props.success}
                      />
                    )}
                    {!userService.isAdministrador() &&
                      filaAtendimento != null &&
                      filaAtendimento.status != objectsConstants.MARCADO &&
                      marcacoesAguardandoAtendimento.length > 0 && (
                        <FilaMarcacaoButton
                          fila={marcacoesAguardandoAtendimento}
                          onChange={this.iniciarAtendimentoMarcacao}
                        />
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            </div>

            {/*{userService.isMedico() && (*/}
            {/*  <div className="local-atendimento-fixed d-flex w-100 justify-content-between align-items-center">*/}
            {/*    {dateHelper.isToday(this.props.date) &&*/}
            {/*      this.mountButtonOutOfSchedule()}*/}
            {/*    {!dateHelper.isToday(this.props.date) && (*/}
            {/*      <div className="col-3"></div>*/}
            {/*    )}*/}
            {/*    {dateHelper.isToday(this.props.date) && (*/}
            {/*      <React.Fragment>*/}

            {/*      </React.Fragment>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*)}*/}

            <ModalCrud
              title="Agendar paciente"
              toogle={this.togglePacientes}
              isOpen={this.state.showNewPaciente}
              Componente={MarcacaoForm}
              agendamentoMode={true}
              pacienteOnline={this.props.pacienteOnline}
              viewMode={false}
              entity={this.state.paciente}
              callbackOnSave={this.handleMarcacaoPaciente}
              onCancel={this.cancelShowNewPaciente}
              estabelecimento={this.state.estabelecimento}
              titleBtnSave={'Agendar'}
              hideAddres={true}
              hideCarteirinha={true}
              medico={this.state.medico}
              estabelecimentoAtendimento={this.state.estabelecimentoAtendimento}
              tipoAgenda={this.props.tipoAgenda}
              dataConsulta={this.props.date}
              permissionSave={objectsConstants.AGENDA_MARCAR}
              showRecorrente={true}
            ></ModalCrud>
            <ModalCrud
              title="Confirmar dados do paciente"
              pacienteOnline={this.props.pacienteOnline}
              toogle={this.toggleConfirmPacientes}
              isOpen={this.state.showConfirmPaciente}
              Componente={MarcacaoForm}
              agendamentoMode={true}
              isEditOrConfirmMode={this.state.novoStatus}
              viewMode={false}
              entity={_.get(this.state, 'paciente')}
              callbackOnSave={this.handleSavePacienteAndChange}
              onCancel={this.cancelShowConfirmPaciente}
              estabelecimento={this.state.estabelecimento}
              titleBtnSave={
                this.state.novoStatus
                  ? this.state.edicaoDeConsulta
                    ? 'Alterar'
                    : 'Confirmar'
                  : 'Agendar'
              }
              hideAddres={
                this.state.novoStatus
                  ? this.state.edicaoDeConsulta
                    ? true
                    : false
                  : true
              }
              hideCarteirinha={
                this.state.novoStatus
                  ? this.state.edicaoDeConsulta
                    ? true
                    : false
                  : true
              }
              confirmaPresenca={this.state.novoStatus}
              medico={this.state.medico}
              estabelecimentoAtendimento={this.state.estabelecimentoAtendimento}
              tipoAgenda={this.props.tipoAgenda}
              dataConsulta={this.props.date}
              permissionSave={objectsConstants.AGENDA_MARCAR}
              showRecorrente={
                this.state.novoStatus
                  ? this.state.edicaoDeConsulta
                    ? false
                    : false
                  : true
              }
            ></ModalCrud>

            <ModalCrud
              title="Triagem"
              pacienteOnline={this.props.pacienteOnline}
              toogle={this.toggleTriagem}
              isOpen={this.state.showTriagem}
              paciente={this.state.paciente}
              Componente={TriagemPage}
              agendamentoMode={true}
              className={'modal-filaAtendimento modal-lg'}
              viewMode={false}
              entity={_.get(this.state, 'marcacao')}
              callbackOnSave={this.handleSaveTriagem}
              onCancel={this.cancelShowConfirmPaciente}
              permissionSave={objectsConstants.AGENDA_TRIAGEM}
              titleBtnSave={'Salvar'}
              {...otherProps}
            ></ModalCrud>

            {Object.entries(estabelecimentos).map(
              ([nomeEstabelecimento, listHorariosEsta]) => {
                let estabelecimento = _.get(
                  _.find(listHorariosEsta, function (itemHorario) {
                    return (
                      itemHorario.estabelecimento.nome === nomeEstabelecimento
                    );
                  }),
                  'estabelecimento'
                );

                let medicos = _.groupBy(listHorariosEsta, function (agenda) {
                  return _.get(agenda, 'medico.nome');
                });

                if (
                  this.props.tipoAgenda ===
                  objectsConstants.TIPO_AGENDA_CONSULTAS
                ) {
                  let nomeMedicos = _.sortBy(Object.keys(medicos));

                  return nomeMedicos.map((nomeMedico) => {
                    let listHorarios = medicos[nomeMedico];
                    let medico = _.get(
                      _.find(listHorarios, function (itemHorario) {
                        return _.get(itemHorario, 'medico.nome') === nomeMedico;
                      }),
                      'medico'
                    );
                    console.log(medico);

                    let estabelecimentoAtendimento = _.get(
                      _.find(listHorarios, function (item) {
                        return _.get(item, 'estabelecimentoAtendimento');
                      }),
                      'estabelecimentoAtendimento'
                    );

                    let dataFinal = _.get(
                      _.find(listHorarios, function (item) {
                        return _.get(item, 'dataFinal');
                      }),
                      'dataFinal'
                    );

                    return (
                      <React.Fragment key={nomeMedico}>
                        {userService.isMedico() &&
                          this.state.estabelecimentosPendentes &&
                          this.state.estabelecimentosPendentes.length > 0 && (
                            <React.Fragment>
                              <div className="col-12 doctor">
                                <div className="top d-flex flex-wrap">
                                  {this.state.estabelecimentosPendentes.map(
                                    (estabelecimentoPendente, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {this.getHeader(
                                            estabelecimentoPendente.estabelecimento,
                                            estabelecimentoPendente
                                              .estabelecimento.nome,
                                            medico.apelido,
                                            index,
                                            dataFinal,
                                            medico.id
                                          )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                  <div className="background"></div>
                                </div>
                                <div className="barra-info pl-3 py-2">
                                  Horários e atendimentos
                                </div>
                                <div className="schedules">
                                  <p className="small pt-4 p-3 txt-red">
                                    {' '}
                                    Pendência, entre em contato com o
                                    administrador do estabelecimento!
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                        <div className="col-12 doctor" id={nomeMedico}>
                          <div
                            className="top d-flex flex-wrap align-items-start align-items-sm-center"
                            id={_.get(medico, 'id')}
                          >
                            {!userService.isMedico() &&
                              this.getHeader(
                                medico,
                                _.get(medico, 'apelido'),
                                _.get(medico, 'especialidades', [])
                                  .map((e) => e.nome)
                                  .join(','),
                                _.get(medico, 'id'),
                                dataFinal,
                                _.get(medico, 'id')
                              )}
                            {userService.isMedico() && (
                              <React.Fragment>
                                {this.getHeader(
                                  estabelecimento,
                                  estabelecimento.nome,
                                  medico.apelido,
                                  _.get(medico, 'id'),
                                  dataFinal,
                                  _.get(medico, 'id')
                                )}
                              </React.Fragment>
                            )}
                            {dateHelper.isToday(this.props.date) &&
                              (userService.isMedico() ||
                                (this.state.listLocalAtendimento &&
                                  this.state.listLocalAtendimento.length >
                                    0)) && (
                                <Can
                                  I={objectsConstants.VIEW}
                                  a={
                                    objectsConstants.ALTERAR_LOCAL_ATENDIMENTO_MEDICO
                                  }
                                  ability={this.props.permissions}
                                >
                                  <div className="col mt-1">
                                    <InputViewEdit
                                      component={FormSelectInput}
                                      type="text"
                                      name="consultorio"
                                      label=""
                                      className="select-barra"
                                      placeholder="Selecione o consultorio ou sala"
                                      onChange={(name, value) => {
                                        if (value) {
                                          this.onChange(
                                            estabelecimento.id,
                                            value,
                                            userService.isMedico()
                                              ? userService.getCurrentUser()
                                              : medico
                                          );
                                        } else {
                                          this.limpa(estabelecimento.id);
                                        }
                                      }}
                                      noSize={true}
                                      viewMode={false}
                                      id="consultorio"
                                      returnFullObject={true}
                                      service={
                                        userService.isMedico()
                                          ? estabelecimentoService.findLocalAtendimento
                                          : null
                                      }
                                      options={
                                        userService.isMedico()
                                          ? null
                                          : this.state.listLocalAtendimento
                                      }
                                      labelKey={'nome'}
                                      valueKey={'id'}
                                      value={this.getLocal(
                                        estabelecimento.id,
                                        userService.isMedico()
                                          ? userService.getCurrentUser()
                                          : medico
                                      )}
                                      required={true}
                                      parent={estabelecimento.id}
                                      clearable={true}
                                    />
                                  </div>
                                </Can>
                              )}
                            <ModalLocal
                              key={estabelecimento.id}
                              ref={(r) =>
                                this.setRef(`ref_${estabelecimento.id}`, r)
                              }
                              name="local"
                              target={'local'}
                              getLocal={this.getLocal}
                              estabelecimento={estabelecimento}
                              callbackOnSave={this.salvarModalLocal}
                              {...otherProps}
                            />

                            {!this.props.pacienteOnline && (
                              <div className="count d-none d-lg-block">
                                {_this.countMarcacoes(marcacoes)} /{' '}
                                {this.props.maxMarcacaoDia}
                              </div>
                            )}
                            <div className="background"></div>
                          </div>
                          <div className="barra-info pl-3 py-2">
                            Horários e atendimentos
                          </div>
                          <div className="schedules">
                            {listHorarios
                              .sortBy('horario')
                              .map((itemHorario, index) => {
                                // HorarioRow de CONSULTAS
                                return (
                                  <HorarioRow
                                    permissions={this.props.permissions}
                                    history={this.props.history}
                                    iniciarTriagem={this.toggleTriagem}
                                    isAgendamentoOnline={
                                      this.props.isAgendamentoOnline
                                    }
                                    updateMarcacao={this.props.updateMarcacao}
                                    medico={itemHorario.medico}
                                    user={this.props.user}
                                    key={
                                      itemHorario.horario +
                                      '_' +
                                      _.get(itemHorario, 'paciente.id', 'P') +
                                      '_' +
                                      _.get(itemHorario, 'exame.id', 'E')
                                    }
                                    listEncaixe={itemHorario.listaEncaixe}
                                    ref={(r) => {
                                      this.setRef(
                                        `compRef_${itemHorario.horario}`,
                                        r
                                      );
                                    }}
                                    horario={itemHorario.horario}
                                    indisponivel={itemHorario.foraDaAgenda}
                                    reservado={itemHorario.horarioReservado}
                                    horarioReservadoObj={
                                      itemHorario.horarioReservadoObj
                                    }
                                    estabelecimentoAtendimento={
                                      itemHorario.estabelecimentoAtendimento
                                    }
                                    value={_this.findMarcacoes(
                                      marcacoes,
                                      itemHorario
                                    )}
                                    itemHorario={itemHorario}
                                    remarcar={this.remarcar}
                                    onStatusChanged={(
                                      value,
                                      status,
                                      confirmar
                                    ) => {
                                      this.statusChange(
                                        value,
                                        status,
                                        confirmar,
                                        estabelecimento
                                      );
                                    }}
                                    changeStatusMarcacao={
                                      this.props.onStatusChanged
                                    }
                                    estabelecimento={estabelecimento}
                                    handlePacienteOnChange={(paciente) => {
                                      this.handleSelectPaciente(
                                        paciente,
                                        itemHorario.horario,
                                        itemHorario.medico,
                                        itemHorario.estabelecimentoAtendimento,
                                        estabelecimento,
                                        _.get(itemHorario, 'atendeTelemedicina')
                                      );
                                    }}
                                    handleLoginOnline={this.handleLoginOnline}
                                    date={this.props.date}
                                    onAnamneseClick={this.handleAnamneseClick}
                                    onPacienteClick={this.handlePacienteClick}
                                    onNewOptionClick={(nome, tipo) =>
                                      this.handleNewOptionClick(
                                        nome,
                                        tipo,
                                        itemHorario.horario,
                                        itemHorario.medico,
                                        itemHorario.estabelecimentoAtendimento,
                                        estabelecimento
                                      )
                                    }
                                    loading={this.props.loading}
                                    onMarcarClick={this.doSaveMarcacao}
                                    marcacao={this.props.marcacao}
                                    warning={this.props.warning}
                                    success={this.props.success}
                                    exame={false}
                                    reagendarMarcacao={this.reagendarMarcacao}
                                    abreHistoricoConsulta={(marcacao) => {
                                      this.props.receiveIdEntityHistorico(
                                        _.get(marcacao, 'idMarcacao'),
                                        objectsConstants.TIPO_HISTORICO_MARCACAO
                                      );
                                      this.props.abreHistorico();
                                    }}
                                    pacienteOnline={this.props.pacienteOnline}
                                    reagendamento={this.props.reagendamento}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  });
                } else if (
                  this.props.tipoAgenda === objectsConstants.TIPO_AGENDA_EXAMES
                ) {
                  let exames = _.groupBy(listHorariosEsta, function (agenda) {
                    return _.get(agenda, 'exame.nome');
                  });
                  return Object.entries(exames).map(
                    ([nomeExame, listHorarios]) => {
                      let exame = _.get(
                        _.find(listHorarios, function (itemHorario) {
                          return _.get(itemHorario, 'exame.nome') === nomeExame;
                        }),
                        'exame'
                      );

                      return (
                        <React.Fragment key={nomeExame}>
                          <div className="col-12 doctor">
                            <div className="top d-flex flex-wrap ">
                              {!userService.isMedico() &&
                                this.getHeaderExame(exame)}
                              <div className="count d-none d-lg-block">
                                {_this.countMarcacoes(marcacoes)} /{' '}
                                {this.props.maxMarcacaoDia}
                              </div>
                              <div className="background"></div>
                            </div>
                            <div className="barra-info pl-3 py-2">
                              Horários e atendimentos
                            </div>
                            <div className="schedules">
                              {listHorarios
                                .sortBy('horario')
                                .map((itemHorario, index) => {
                                  // HorarioRow de EXAMES
                                  return (
                                    <HorarioRow
                                      permissions={this.props.permissions}
                                      history={this.props.history}
                                      iniciarTriagem={this.toggleTriagem}
                                      isAgendamentoOnline={
                                        this.props.isAgendamentoOnline
                                      }
                                      estabelecimento={estabelecimento}
                                      updateMarcacao={this.props.updateMarcacao}
                                      key={
                                        itemHorario.horario +
                                        '_' +
                                        _.get(itemHorario, 'paciente.id', 'P') +
                                        '_' +
                                        _.get(itemHorario, 'exame.id', 'E')
                                      }
                                      ref={(r) =>
                                        this.setRef(
                                          `compRef_${itemHorario.horario}`,
                                          r
                                        )
                                      }
                                      horario={itemHorario.horario}
                                      indisponivel={itemHorario.foraDaAgenda}
                                      estabelecimentoAtendimento={
                                        itemHorario.estabelecimentoAtendimento
                                      }
                                      value={_this.findMarcacoes(
                                        marcacoes,
                                        itemHorario
                                      )}
                                      remarcar={this.remarcar}
                                      onStatusChanged={(
                                        value,
                                        status,
                                        confirmar
                                      ) => {
                                        this.statusChange(
                                          value,
                                          status,
                                          confirmar,
                                          estabelecimento
                                        );
                                      }}
                                      itemHorario={itemHorario}
                                      estabelecimento={estabelecimento}
                                      changeStatusMarcacao={
                                        this.props.onStatusChanged
                                      }
                                      handlePacienteOnChange={(paciente) => {
                                        this.handleSelectPacienteExame(
                                          paciente,
                                          itemHorario.horario,
                                          itemHorario.estabelecimentoAtendimento,
                                          estabelecimento,
                                          _.get(itemHorario, 'exame.id')
                                        );
                                      }}
                                      date={this.props.date}
                                      onAnamneseClick={this.handleAnamneseClick}
                                      onPacienteClick={this.handlePacienteClick}
                                      onNewOptionClick={(nome, tipo) =>
                                        this.handleNewOptionClick(
                                          nome,
                                          tipo,
                                          itemHorario.horario,
                                          null,
                                          itemHorario.estabelecimentoAtendimento,
                                          estabelecimento,
                                          _.get(itemHorario, 'exame.id')
                                        )
                                      }
                                      loading={this.props.loading}
                                      onMarcarClick={this.doSaveMarcacao}
                                      marcacao={this.props.marcacao}
                                      warning={this.props.warning}
                                      success={this.props.success}
                                      exame={true}
                                      idExame={_.get(itemHorario, 'exame.id')}
                                      exameObj={exame}
                                      reservado={itemHorario.horarioReservado}
                                      horarioReservadoObj={
                                        itemHorario.horarioReservadoObj
                                      }
                                      reagendarMarcacao={this.reagendarMarcacao}
                                      abreHistoricoConsulta={(marcacao) => {
                                        this.props.receiveIdEntityHistorico(
                                          _.get(marcacao, 'idMarcacao'),
                                          objectsConstants.TIPO_HISTORICO_MARCACAO
                                        );
                                        this.props.abreHistorico();
                                      }}
                                      pacienteOnline={this.props.pacienteOnline}
                                      user={this.props.user}
                                      reagendamento={this.props.reagendamento}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    }
                  );
                }
              }
            )}
          </React.Fragment>
        )}
        <ModalCrud
          title="Consulta fora da agenda"
          toogle={this.togglePacientesConsultaAvulsa}
          isOpen={this.state.showPacienteConsultaAvulsa}
          Componente={MarcacaoForm}
          agendamentoMode={true}
          viewMode={false}
          entity={this.state.paciente}
          callbackOnSave={this.handleSavePacienteAndChangeConsultaAvulsa}
          onCancel={this.cancelShowPacienteConsultaAvulsa}
          estabelecimento={this.state.estabelecimento}
          titleBtnSave={'Atender'}
          hideAddres={false}
          hideCarteirinha={this.state.novoStatus ? false : true}
          medico={this.state.medico}
          estabelecimentoAtendimento={this.state.estabelecimentoAtendimento}
          tipoAgenda={this.props.tipoAgenda}
          dataConsulta={this.props.date}
          consultaAvulsa={true}
          permissionSave={objectsConstants.AGENDA_MARCAR}
          showRecorrente={false}
        ></ModalCrud>
      </React.Fragment>
    );
  }
  reagendarMarcacao = (marcacao) => {
    this.props.loading(true);
    marcacaoService.doSave(marcacao).then(
      (response) => {
        setTimeout(() => {
          this.props.success({
            message: `Paciente reagendado com sucesso!`,
          });

          this.props.history.push({
            pathname: '/agenda',
            state: { notUpdate: true },
          });
          this.props.loading(false);
        }, 0);
      },
      (error) => {
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível reagendar.',
        });
        console.log(error);
      }
    );
  };

  handlePacienteClick = (idPaciente) => {
    this.props.history.push({
      pathname: '/paciente/' + idPaciente + '/' + true,
      state: { mes: this.props.mes, ano: this.props.ano },
    });
  };

  handleAnamneseClick = (idPaciente, idMarcacao) => {
    if (idMarcacao) {
      this.props.history.push('/prontuario/' + idPaciente + '/' + idMarcacao);
    } else {
      this.props.history.push('/prontuario/' + idPaciente);
    }
  };

  handleLoginOnline = (stateObject) => {
    this.props.history.push({
      pathname: '/agendaOnline/login',
      state: stateObject,
    });
  };

  handleGoBack = () => {
    this.setState({ showAnamnese: false, idPaciente: 0 });
    this.changeGoBack();
    this.props.dispatch(headerAction.changeExtraButton(false));
    this.props.dispatch(agendaAction.showDivMedico(true));
  };
}

Array.prototype.sortBy = function (p) {
  return this.slice(0).sort(function (a, b) {
    return a[p] > b[p] ? 1 : a[p] < b[p] ? -1 : 0;
  });
};

function mapStateToProps(state) {
  const { user, permissions, loggedIn } = state.authentication;
  const { agendaDoDia, dataAgendaDia, fila } = state.agenda;
  return {
    user,
    agendaDoDia,
    dataAgendaDia,
    fila,
    permissions,
    loggedIn,
  };
}
const mapDispatch = ({
  pageTitle: { changePageTitle },
  load: { loading },
  authentication: { refreshUser },
  agendaOnline: { setPaciente },
  alert: { error, warning, success },
  agenda: { setAgendaDoDia, updateMarcacao },
  historicomenu: {
    toggleHistorico,
    abreHistorico,
    fechaHistorico,
    receiveIdEntityHistorico,
  },
}) => ({
  changePageTitle: (pageTitle) => changePageTitle(pageTitle),
  loading: (load: boolean) => loading({ load }),
  refreshUser: (payload) => refreshUser(payload),
  setPaciente: (paciente) => setPaciente({ paciente }),
  error: (msg) => error(msg),
  warning: (msg) => warning(msg),
  success: (msg) => success(msg),
  setAgendaDoDia: (agendaDoDia) => setAgendaDoDia(agendaDoDia),
  updateMarcacao: (marcacao) => updateMarcacao({ marcacao }),
  toggleHistorico: () => toggleHistorico(),
  abreHistorico: () => abreHistorico({}),
  receiveIdEntityHistorico: (idEntityHistorico, tipoHistorico) =>
    receiveIdEntityHistorico({ idEntityHistorico, tipoHistorico }),
  fechaHistorico: () => fechaHistorico(),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(AgendaHorariosPage));
