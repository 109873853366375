//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import IconDelete from '../../images/icon_x_red.svg';
import { convenioService } from '../../_services/convenio.service';
import CreatableMulti from '../inputs/CreatableMulti';
import FormSelectInput from '../inputs/FormSelectInput';
import InputViewEdit from '../inputs/InputViewEdit';
import FormInput from '../inputs/FormInput';
import DatePickerInput from '../inputs/DatePickerInput';
import { dateHelper } from '../../_helpers/date.helper';
import SelectInput from '../inputs/SelectInput';
import { FormGroup } from '../FormGroup';
import { Form } from 'formik';
import { objectsConstants } from '../../_constants/objects.constants';

type Props = {
  entity: any,
};

type State = {
  daysOfWeek: [],
};

class ConveniosPlanosPaciente extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      entity,
      propName,
      medico,
      index,
      viewMode,
      size,
      agendamentoMode,
      estabelecimento,
      hideCarteirinha,
      errors,
      estabelecimentoAtendimento,
    } = this.props;

    return (
      <div className="form-inside">
        {!this.props.atendePlano &&
          agendamentoMode && (
            <div className="row section-form">
              <div className="txt-red pl-3 font-xs">
                <b>{this.props.validationText}</b>
              </div>
            </div>
          )}
        <div className="row section-form">
          <FormGroup
            className={`col-12 ${
              agendamentoMode ? 'col-sm-6 pt-1' : 'col-sm-3 pt-1'
            } `}
          >
            <InputViewEdit
              label="Convênio"
              placeholder="Selecione um convênio"
              component={FormSelectInput}
              service={
                convenioService.findByEstabelecimentoOrEstabelecimentoMedico
              }
              labelKey={'sigla'}
              valueKey={'id'}
              returnFullObject={true}
              defaultValue={_.get(entity, `${propName}.convenio.sigla`, '--')}
              className={''}
              required={false}
              value={_.get(entity, `${propName}.convenio`)}
              name={`${propName}.convenio`}
              viewMode={viewMode}
              id={`convenio${index}Item`}
              onChange={(name, value) => {
                this.props.onChange(`${propName}.planoConvenio`, null);
                this.props.onChange(name, value);
              }}
              erroMensagem={_.get(
                this.props.errors,
                `pacienteConvenios[${index}].convenio`
              )}
              parent={
                estabelecimento
                  ? {
                      estabelecimento: estabelecimento.id,
                      medico: medico ? medico.id : null,
                    }
                  : null
              }
              parent={{
                estabelecimento: estabelecimento ? estabelecimento.id : null,
                estabelecimentoMedico: estabelecimentoAtendimento
                  ? estabelecimentoAtendimento.id
                  : null,
              }}
              sortNoAsc={true}
              sortKey={'sigla'}
              showToolTip={false}
            />
          </FormGroup>
          <FormGroup
            className={`col-12 ${
              agendamentoMode ? 'col-sm-5 pt-1' : 'col-sm-4 pt-1'
            } `}
          >
            <InputViewEdit
              label="Plano"
              placeholder="Selecione um plano"
              component={SelectInput}
              labelKey={'nome'}
              valueKey={'id'}
              returnFullObject={true}
              defaultValue={_.get(
                entity,
                `${propName}.planoConvenio.nome`,
                '--'
              )}
              className={''}
              value={_.get(entity, `${propName}.planoConvenio`)}
              name={`${propName}.planoConvenio`}
              viewMode={viewMode}
              id={`convenio${index}plano`}
              onChange={this.props.onChange}
              erroMensagem={_.get(
                this.props.errors,
                `pacienteConvenios[${index}].planoConvenio`
              )}
              options={_.get(
                entity,
                `${propName}.convenio.listPlanoConvenio`,
                []
              )}
              hideToolTip={false}
            />
          </FormGroup>

            <React.Fragment>
              <FormGroup
                className={`col-12 ${
                  agendamentoMode ? 'col-lg-6' : 'col-lg-6'
                } `}
              >
                <InputViewEdit
                  label="Número da carteirinha"
                  viewMode={viewMode}
                  component={FormInput}
                  type="text"
                  id={`carteirinha${index}`}
                  name={`${propName}.numeroCarteirinha`}
                  placeholder="Carteirinha"
                  value={_.get(entity, `${propName}.numeroCarteirinha`)}
                  onChange={this.props.onChange}
                  required={!hideCarteirinha}
                  erroMensagem={_.get(
                    this.props.errors,
                    `${propName}.numeroCarteirinha`
                  )}
                />
              </FormGroup>
              <FormGroup
                className={`col-12 ${
                  agendamentoMode ? 'col-lg-6' : 'col-lg-6'
                } `}
              >
                <InputViewEdit
                  label="Validade carteirinha"
                  viewMode={viewMode}
                  format={'DD/MM/YYYY'}
                  component={DatePickerInput}
                  id={`dataValidadeCarteirinha${index}`}
                  name={`${propName}.dataValidadeCarteirinha`}
                  value={_.get(entity, `${propName}.dataValidadeCarteirinha`)}
                  onChange={this.props.onChange}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  required={!hideCarteirinha}
                  placeholder={'--/--/----'}
                  defaultValue={dateHelper.format(
                    _.get(entity, `${propName}.dataValidadeCarteirinha`),
                    {
                      mode: 'DATE',
                    }
                  )}
                  erroMensagem={_.get(
                    this.props.errors,
                    `${propName}.dataValidadeCarteirinha`
                  )}
                />
              </FormGroup>
            </React.Fragment>


          {!viewMode && !agendamentoMode && !hideCarteirinha && (
            <FormGroup
              className={`col-12 col-lg-1 p-0 d-flex align-items-center justify-content-center ${
                viewMode ? 'd-none' : ' d-flex '
              }`}
            >
              <div
                className={`icon-delete d-flex align-items-center justify-content-center ${
                  size === 1 || viewMode ? 'disabled' : ''
                }`}
                onClick={() => {
                  this.props.removeItem(index, entity);
                }}
                disabled={size === 1}
              >
                <img src={IconDelete} alt="img" />
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
  }
}

export default ConveniosPlanosPaciente;
